import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from "firebase/firestore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Day from "../data/Day";
import Meal from "../data/Meal";

export interface DayState {
  userId: string;
  selectedDay: Day | null;
  days: Day[];
}

const initialState: DayState = {
  userId: "",
  selectedDay: null,
  days: []
};

export const convertDateString = (dayStr: string | undefined) => {
  if (dayStr === null || dayStr === undefined) {
    return null;
  } else if (dayStr === "today") {
    const today = new Date();
    let dateString = `${today.getFullYear()}.`;
    if (today.getMonth() + 1 < 10) {
      dateString += `0${(today.getMonth() + 1).toString()}.`;
    } else {
      dateString += `${(today.getMonth() + 1).toString()}.`;
    }
    if (today.getDate() + 1 < 10) {
      dateString += `0${(today.getDate() + 1).toString()}`;
    } else {
      dateString += `${(today.getDate() + 1).toString()}`;
    }
    return dateString;
  }

  return dayStr;
};

export const addMealToDay = (day: Day, meal: Meal) => {
  let newDay: Day = { ...day };
  if (meal.amount === null) {
    meal.amount = 1;
  }
  if (newDay.meals === null) {
    newDay.meals = [];
    newDay.calories = 0;
    newDay.protein = 0;
    newDay.carbs = 0;
    newDay.fats = 0;
  }
  let containsMeal = false;
  newDay.meals.map((value, index) => {
    containsMeal = containsMeal || value.uid === meal.uid;
  });

  let mealIndex: number | undefined;
  if (containsMeal) {
    // remove it
    mealIndex = newDay.meals.findIndex(item => item.uid === meal.uid);
    const foundMeal = newDay.meals[mealIndex];
    newDay.calories -=
      meal.calories * (foundMeal.amount === null ? 1 : foundMeal.amount);
    newDay.protein -=
      meal.protein * (foundMeal.amount === null ? 1 : foundMeal.amount);
    newDay.carbs -=
      meal.carbs * (foundMeal.amount === null ? 1 : foundMeal.amount);
    newDay.fats -=
      meal.fats * (foundMeal.amount === null ? 1 : foundMeal.amount);
  }
  // now add it in
  if (meal.amount !== 0) {
    if (mealIndex !== undefined && mealIndex !== null && mealIndex > -1) {
      let newMealsList: Meal[] = [];
      Object.assign(newMealsList, newDay.meals, { mealIndex: meal });
      newMealsList[mealIndex] = meal;
      newDay.meals = newMealsList;
    } else {
      newDay.meals.push(meal);
    }
    newDay.calories += meal.calories * (meal.amount === null ? 1 : meal.amount);
    newDay.protein += meal.protein * (meal.amount === null ? 1 : meal.amount);
    newDay.carbs += meal.carbs * (meal.amount === null ? 1 : meal.amount);
    newDay.fats += meal.fats * (meal.amount === null ? 1 : meal.amount);
  } else {
    // need to remove it from the list
    const updatedList: Meal[] = newDay.meals.filter(m => m.uid !== meal.uid);
    newDay.meals = updatedList;
  }

  return newDay;
};

export interface MealDayRequest {
  day: Day | null;
  meal: Meal | null;
  amount: number;
}

interface SetDaysOfMonthRequest {
  days: Day[];
}

export const daysSlice = createSlice({
  name: "days",
  initialState: initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
      return state;
    },
    setDay: (state, action: PayloadAction<Day | null>) => {
      state.selectedDay = action.payload;
      // TODO also update the Day in the list of days if nessisary
      return state;
    },
    setDaysOfMonth: (state, action: PayloadAction<SetDaysOfMonthRequest>) => {
      let newMonth: Day[] = [];
      newMonth = [...action.payload.days];
      state.days = newMonth;
      return state;
    },
    addMealForDay: (state, action: PayloadAction<MealDayRequest>) => {
      // NOTE: we only look at the meal from the meal request. We do not update other days
      //         We always treat this as if we are looking at the currently selected day.
      //         Can pass the day in case the current selected day is null
      if (action.payload.meal === null) {
        return state;
      }
      if (state.selectedDay === null) {
        state.selectedDay = action.payload.day;
      }
      if (state.selectedDay !== null) {
        state.selectedDay = addMealToDay(state.selectedDay, {
          ...action.payload.meal,
          amount: action.payload.amount
        });
      }
      // then update in list if its there
      const dayIndex = state.days.findIndex(item => {
        if (action.payload.day === null) {
          return -1;
        }
        return item.uid === action.payload.day.uid;
      });
      if (dayIndex !== -1 && state.selectedDay !== null) {
        // So this day exists in the list of days in our state.
        // Thus we need to swap it out with what we just updated
        state.days[dayIndex] = state.selectedDay;
      }
      return state;
    }
  }
});

export const daysActions = daysSlice.actions;
