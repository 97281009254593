import React, { useRef, useState } from "react";
import Meal from "../../data/Meal";
import { useSelector, useDispatch } from "react-redux";

import cssClasses from "./AddMeal.module.css";
import { EditMealRequest, mealActions } from "../../stores/meals";
import { Navigate } from "react-router";
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from "../../Store";

function AddMealPage() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [meal, setMeal] = useState<Meal>({
    uid: "",
    name: "",
    route: "",
    calories: 0,
    protein: 0,
    fats: 0,
    carbs: 0,
    cost: 0,
    ingredients: [],
    tags: [],
    amount: 1
  });

  const { mealId } = useParams();

  if (mealId !== undefined && meal.uid === "") {
    // Then we are editing a meal
    dispatch(mealActions.setSelectedMeal(mealId));
    // then we are editing a meal
    const foundMeal = useSelector<RootState, Meal | null>(state => {
      return state.meals.selectedMeal;
    });
    if (foundMeal !== null) {
      setMeal(foundMeal);
    }
  }

  const cancel = () => {
    if (mealId !== undefined) {
      navigate("/meals/" + mealId);
    } else {
      navigate("/meals");
    }
  };

  const submitForm = () => {
    const newMealRequest: EditMealRequest = {
      meal:
        meal.uid !== ""
          ? meal
          : {
              ...meal,
              uid:
                meal.route.replaceAll("_", "-") +
                "_" +
                meal.name.replaceAll("_", "-")
            }
    };
    dispatch(mealActions.createMeal(newMealRequest));
    setHasSubmitted(true);
  };
  if (hasSubmitted) {
    return <Navigate to="/meals" />;
  }
  return (
    <div className={cssClasses.outer}>
      <span />
      <form className={cssClasses["form-input"]} onSubmit={submitForm}>
        <div className={cssClasses["text-input"]}>
          Name
          <span />
          <input
            required={true}
            value={meal.name}
            onChange={e => {
              const updatedMeal = { ...meal, name: e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Location
          <span />
          <input
            required={true}
            value={meal.route}
            onChange={e => {
              const updatedMeal = { ...meal, route: e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Calories
          <span />
          <input
            required={true}
            type="number"
            min="0"
            step="1"
            value={meal.calories}
            onChange={e => {
              const updatedMeal = { ...meal, calories: +e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Protein
          <span />
          <input
            required={true}
            type="number"
            min="0"
            value={meal.protein}
            onChange={e => {
              const updatedMeal = { ...meal, protein: +e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Carbs
          <span />
          <input
            required={true}
            type="number"
            min="0"
            value={meal.carbs}
            onChange={e => {
              const updatedMeal = { ...meal, carbs: +e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Fats
          <span />
          <input
            required={true}
            type="number"
            min="0"
            value={meal.fats}
            onChange={e => {
              const updatedMeal = { ...meal, fats: +e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["text-input"]}>
          Cost
          <span />
          <input
            required={false}
            type="number"
            min="0"
            value={meal.cost}
            onChange={e => {
              const updatedMeal = { ...meal, cost: +e.target.value };
              setMeal(updatedMeal);
            }}
          />
        </div>
        <div className={cssClasses["submit-row"]}>
          <button type="reset" onClick={cancel}>
            Cancel
          </button>
          <span />
          <button type="submit">Save</button>
        </div>
      </form>
      <span />
    </div>
  );
}

export default AddMealPage;
