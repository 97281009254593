import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Meal from "../data/Meal";

export interface SearchMealRequest {
  searchString: string;
}

export interface EditMealRequest {
  meal: Meal;
}

export interface NutritionState {
  userId: string;
  foundMeals: Meal[];
  selectedMeal: Meal | null;
}

const initialState: NutritionState = {
  userId: "",
  foundMeals: [
    {
      uid: "abcdedf",
      name: "food1",
      calories: 200,
      protein: 30,
      carbs: 20,
      fats: 10,
      route: "Chicfila",
      cost: 5.99,
      amount: 1,
      ingredients: [],
      tags: []
    },
    {
      uid: "abcdedfaaa",
      name: "food2",
      calories: 200,
      protein: 30,
      carbs: 20,
      fats: 10,
      route: "Chicfila",
      cost: 5.99,
      amount: 1,
      ingredients: [],
      tags: []
    },
    {
      uid: "abcdedfgggg",
      name: "food3",
      calories: 200,
      protein: 30,
      carbs: 20,
      fats: 10,
      route: "Chicfila",
      cost: 5.99,
      amount: 1,
      ingredients: [],
      tags: []
    }
  ],
  selectedMeal: null
};

export const mealSlice = createSlice({
  name: "meals",
  initialState: initialState,
  reducers: {
    searchForMeals: (state, action: PayloadAction<SearchMealRequest>) => {
      return state;
    },
    createMeal: (state, action: PayloadAction<EditMealRequest>) => {
      let foundMeal = false;
      state.foundMeals.forEach(
        meal => (foundMeal = foundMeal && meal.uid === action.payload.meal.uid)
      );
      if (!foundMeal) {
        // then add it
        state.foundMeals.push(action.payload.meal);
      }

      // TODO send to firebase
      return state;
    },
    setSelectedMeal: (state, action: PayloadAction<String>) => {
      const mealIndex = state.foundMeals.findIndex(
        meal => meal.uid === action.payload
      );
      if (mealIndex > -1) {
        state.selectedMeal = state.foundMeals[mealIndex];
      } else {
        state.selectedMeal = null;
      }
      return state;
    },
    editMeal: (state, action: PayloadAction<EditMealRequest>) => {
      const mealIndex = state.foundMeals.findIndex(
        meal => meal.uid === action.payload.meal.uid
      );
      if (mealIndex > 0) {
        state.foundMeals[mealIndex] = action.payload.meal;
      }
      return state;
    },
    deleteMeal: (state, action: PayloadAction<EditMealRequest>) => {
      state.foundMeals = state.foundMeals.filter(
        meal => meal.uid !== action.payload.meal.uid
      );
      // TODO delete from firebase
      return state;
    }
  }
});

export const mealActions = mealSlice.actions;
