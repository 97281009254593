import React from "react";
import { useParams } from "react-router-dom";

import cssClasses from "./NotFound.module.css";

function NotFound() {
  const { notFoundType } = useParams();
  if (notFoundType === null || notFoundType === undefined) {
    return <div className={cssClasses.outer}>404 Not found</div>;
  }
  return (
    <div className={cssClasses.outer}>
      Could not find {notFoundType === "meal" ? "meal" : "day"}!
    </div>
  );
}

export default NotFound;
