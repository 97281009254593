import React, { useEffect, useState } from "react";

import cssClasses from "./Day.module.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Store";
import Day from "../../data/Day";
import {
  addMealToDay,
  convertDateString,
  daysActions,
  MealDayRequest
} from "../../stores/days";
import NutritionChart from "../../components/NutritionChart/NutrtionChart";
import Meal from "../../data/Meal";
import {
  getDay,
  updateOrCreateDay,
  updateOrCreateDayWithId
} from "../../firebase";
import Loading from "../../components/Loading/Loading";

function DayPage() {
  let navigate = useNavigate();
  let mealsForToday: JSX.Element[] = [];
  let exersizesForToday: JSX.Element[] = [];
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState<Day>({
    month: "",
    uid: "temp",
    calories: 0,
    protein: 0,
    carbs: 0,
    fats: 0,
    weight: null,
    waist: null,
    date: new Date(),
    meals: [],
    exersizes: []
  });
  const userId = useSelector<RootState, string>(state => state.days.userId);
  const { dayId } = useParams();
  // tell redux which day you are on and try to pull in the data required
  const converted = convertDateString(dayId);
  if (converted === null) {
    return <Navigate to="/notfound/day" />;
  }

  const currentlySetDay = useSelector<RootState, Day | null>(
    state => state.days.selectedDay
  );

  if (selectedDay.uid === "temp") {
    if (currentlySetDay === null || currentlySetDay.uid !== converted) {
      getDay(userId, converted).then((foundDay: Day) => {
        dispatch(daysActions.setDay(foundDay));
        setSelectedDay(foundDay);
      });
      return (
        <div className={cssClasses.outer}>
          <Loading />
        </div>
      );
    } else {
      setSelectedDay(currentlySetDay);
      updateOrCreateDayWithId(userId, currentlySetDay);
    }
  }

  const increaseMealAmount = (meal: Meal) => {
    let day = selectedDay;
    let amount = meal.amount === null ? 1 : meal.amount + 1;
    const newMeal: Meal = { ...meal, amount: amount };
    if (day !== null) {
      day = addMealToDay(day, newMeal);
      setSelectedDay(day);
      dispatch(daysActions.setDay(day));
      updateOrCreateDayWithId(userId, day);
    }
  };

  const decreaseMealAmount = (meal: Meal) => {
    let day = selectedDay;
    let amount = meal.amount === null ? 1 : meal.amount - 1;
    const newMeal: Meal = { ...meal, amount: amount };
    if (day !== null) {
      day = addMealToDay(day, newMeal);
      setSelectedDay(day);
      dispatch(daysActions.setDay(day));
      updateOrCreateDayWithId(userId, day);
    }
  };

  const removeMeal = (meal: Meal) => {
    let day = selectedDay;
    const newMeal: Meal = { ...meal, amount: 0 };
    if (day !== null) {
      day = addMealToDay(day, newMeal);
      setSelectedDay(day);
      dispatch(daysActions.setDay(day));
      updateOrCreateDayWithId(userId, day);
    }
  };

  if (selectedDay.meals.length < 1) {
    mealsForToday = [<div>No meals found!</div>];
  } else {
    mealsForToday = selectedDay.meals.map(meal => {
      return (
        <div className={cssClasses.meal}>
          <div className={cssClasses.amount}>
            {meal.amount}
            <div className={cssClasses["change-amount"]}>
              <button onClick={() => increaseMealAmount(meal)}>+</button>
              <button onClick={() => decreaseMealAmount(meal)}>-</button>
              <button
                className={cssClasses["remove"]}
                onClick={() => removeMeal(meal)}
              >
                x
              </button>
            </div>
          </div>
          <h4>{meal.name}</h4>
          <NutritionChart meal={meal} showMacros={true} showCalories={true} />
        </div>
      );
    });
  }
  const addMeal = () => {
    navigate("/meals");
  };
  mealsForToday.push(
    <div className={cssClasses.meal} onClick={addMeal}>
      <b className={cssClasses["add-new"]}>+</b>
    </div>
  );
  if (selectedDay.exersizes.length < 1) {
    exersizesForToday = [<div>No exersizes found!</div>];
  } else {
    exersizesForToday = selectedDay.exersizes.map(exersize => {
      return (
        <div className={cssClasses.exersize}>
          <h4>TBD</h4>
        </div>
      );
    });
  }

  const addExersize = () => {
    navigate("/exersizes");
  };

  exersizesForToday.push(
    <div className={cssClasses.exersize} onClick={addExersize}>
      <b className={cssClasses["add-new"]}>+</b>
    </div>
  );

  let title = "";
  if (selectedDay.date !== null) {
    if (selectedDay.date instanceof Date) {
      title = `${selectedDay.date.getMonth() +
        1}/${selectedDay.date.getDate()}/${selectedDay.date.getFullYear()}`;
    } else {
      let date = new Date(selectedDay.date);
      title = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  }

  return (
    <div className="outer">
      <div className={cssClasses.date}>{title}</div>
      <div className={cssClasses.nutrition}>
        <div className={cssClasses.breakdown}>
          <NutritionChart
            day={selectedDay}
            showCalories={true}
            centerValues={true}
            showMacros={true}
          />
        </div>
        <div className={cssClasses.meals}>{mealsForToday}</div>
      </div>
      <div className={cssClasses.exersizes}>{exersizesForToday}</div>
    </div>
  );
}

export default DayPage;
