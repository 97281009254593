import React, { useState, useEffect } from "react";

import cssClasses from "./Days.module.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Store";
import Day from "../../data/Day";
import { daysActions } from "../../stores/days";
import { getDaysForMonth } from "../../firebase";
import Loading from "../../components/Loading/Loading";
import NutritionChart from "../../components/NutritionChart/NutrtionChart";

function Days() {
  const [foundDays, setFoundDays] = useState<Day[]>([]);
  const [currentlySelectedDate, setMonth] = useState(new Date());
  const dispatch = useDispatch();

  const userId = useSelector<RootState, string>(state => state.days.userId);
  useEffect(() => {
    if (foundDays.length < 1) {
      getDaysForMonth(userId, currentlySelectedDate).then(foundDays => {
        dispatch(daysActions.setDaysOfMonth({ days: foundDays }));
        setFoundDays(foundDays);
      });
    }
  }, [foundDays]);
  if (foundDays.length < 1) {
    return (
      <div className={cssClasses.outer}>
        <Loading />
      </div>
    );
  }
  let displayDays: JSX.Element[] = foundDays.map(day => {
    let title = "";
    if (day.date !== null) {
      if (day.date instanceof Date) {
        title = `${day.date.getMonth() + 1}/${day.date.getDate()}`;
      } else {
        let date = new Date(day.date);
        title = `${date.getMonth() + 1}/${date.getDate()}`;
      }
    }
    return (
      <Link to={"/days/" + day.uid} className={cssClasses.day} key={day.uid}>
        <div className={cssClasses["daycard"]}>
          <b>{title}</b>
          <NutritionChart day={day} centerValues={true} />
          <br />
          <br />
          <br />
          {day.calories} | {day.protein}
        </div>
      </Link>
    );
  });

  // On click methods
  const moveForwardMonth = async () => {
    let newMonth: Date;
    if (currentlySelectedDate.getMonth() === 11) {
      newMonth = new Date(currentlySelectedDate.getFullYear() + 1, 0, 1);
    } else {
      newMonth = new Date(
        currentlySelectedDate.getFullYear(),
        currentlySelectedDate.getMonth() + 1,
        1
      );
    }
    const foundDays: Day[] = await getDaysForMonth(userId, newMonth);
    dispatch(daysActions.setDaysOfMonth({ days: foundDays }));
    setMonth(newMonth);
  };
  const moveBackwardMonth = async () => {
    let newMonth: Date;
    if (currentlySelectedDate.getMonth() === 0) {
      newMonth = new Date(currentlySelectedDate.getFullYear() - 1, 11, 1);
    } else {
      newMonth = new Date(
        currentlySelectedDate.getFullYear(),
        currentlySelectedDate.getMonth() - 1,
        1
      );
    }

    const foundDays: Day[] = await getDaysForMonth(userId, newMonth);
    dispatch(daysActions.setDaysOfMonth({ days: foundDays }));
    setMonth(newMonth);
  };

  // Display logic for non loading
  console.log("non loading render");

  let fullDisplayDays = getDisplayDaysWithSpacers(foundDays, displayDays);
  return (
    <div className={cssClasses.outer}>
      <div className={cssClasses.monthRow}>
        <button
          onClick={moveBackwardMonth}
          className={cssClasses.monthSelector}
        >
          {"<"}
        </button>
        <div className={cssClasses.monthText}></div>
        <div className={cssClasses.monthText}>
          {currentlySelectedDate.toLocaleDateString("default", {
            year: "numeric",
            month: "long"
          })}
        </div>
        <button onClick={moveForwardMonth} className={cssClasses.monthRow}>
          {">"}
        </button>
      </div>
      <div className={cssClasses.daysContainer}>
        <div className={cssClasses.dayContainerSpacer} />
        <div className={cssClasses["days"]}>{fullDisplayDays}</div>
        <div className={cssClasses.dayContainerSpacer} />
      </div>
    </div>
  );
}

export default Days;

function getDisplayDaysWithSpacers(
  foundDays: Day[],
  displayDays: JSX.Element[]
) {
  let spacerDays: JSX.Element[] = [];
  let firstDay = foundDays.at(0);
  if (firstDay && firstDay !== undefined) {
    let offsetDays = new Date(firstDay.date).getDay();
    for (let a = 0; a < offsetDays; a++) {
      spacerDays.push(<div></div>);
    }
  }
  let fullDisplayDays = [...spacerDays, ...displayDays];
  return fullDisplayDays;
}
