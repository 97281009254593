import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import Day from "../../data/Day";
import Meal from "../../data/Meal";
import cssClasses from "./NutritionChart.module.css";

interface NutritionChartProps {
  meal?: Meal | null;
  day?: Day | null;
  showCalories?: boolean;
  showMacros?: boolean;
  centerValues?: boolean;
}

function NutritionChart({
  meal = null,
  day = null,
  showCalories = false,
  showMacros = false,
  centerValues = false
}: NutritionChartProps) {
  const calories =
    meal !== null ? meal.calories : day !== null ? day.calories : 0;
  const protein = meal !== null ? meal.protein : day !== null ? day.protein : 0;
  const carbs = meal !== null ? meal.carbs : day !== null ? day.carbs : 0;
  const fats = meal !== null ? meal.fats : day !== null ? day.fats : 0;
  const baseId =
    meal !== null ? meal.uid : day !== null ? day.uid : "missingvals";
  const proteinId = baseId + "-protein";
  const carbId = baseId + "-carb";
  const fatId = baseId + "-fat";

  return (
    <div>
      <div className={cssClasses["nutrition-chart-container"]}>
        {showCalories && !centerValues ? (
          <>
            <i className={cssClasses.calories}>{calories}</i>
            <i className={cssClasses["calories-description"]}>Calories</i>
          </>
        ) : (
          <></>
        )}
        {showMacros && !centerValues ? (
          <>
            <i className={cssClasses.macros}>{`${protein}/${carbs}/${fats}`}</i>
            <i className={cssClasses["macros-description"]}>p/c/f</i>
          </>
        ) : (
          <></>
        )}
        <PieChart
          className={cssClasses["nutrition-chart"]}
          radius={40}
          lineWidth={40}
          viewBoxSize={[100, 100]}
          data={[
            {
              title: "Protein",
              color: "#B44682",
              value: protein * 4,
              key: proteinId
            },
            {
              title: "Carbs",
              color: "#82B446",
              value: carbs * 4,
              key: carbId
            },
            {
              title: "Fats",
              color: "#4682B4",
              value: fats * 4,
              key: fatId
            }
          ]}
        />
      </div>
      <div className={cssClasses["centered-values"]}>
        <div className={cssClasses["centered-info-container"]}>
          {showCalories && centerValues ? (
            <>
              <i className={cssClasses["centered-calories"]}>{calories}</i>
              <i className={cssClasses["centered-calories-description"]}>
                Calories
              </i>
            </>
          ) : (
            <></>
          )}
          {showMacros && centerValues ? (
            <div className={cssClasses["centered-info-container"]}>
              <i
                className={cssClasses["centered-macros"]}
              >{`${protein}/${carbs}/${fats}`}</i>
              <i className={cssClasses["centered-macros-description"]}>p/c/f</i>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default NutritionChart;
