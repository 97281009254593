import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  CollectionReference,
  DocumentData,
  setDoc,
  doc
} from "firebase/firestore";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Type } from "typescript";
import User from "./data/User";
import Day from "./data/Day";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVtaHA3bhlCPh8Ecf4dhOVefV1n0EoFVQ",
  authDomain: "react-nutrition-40720.firebaseapp.com",
  projectId: "react-nutrition-40720",
  storageBucket: "react-nutrition-40720.appspot.com",
  messagingSenderId: "804798121762",
  appId: "1:804798121762:web:e077323a9b067e3353877b"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const createCollection = (name: string) => collection(db, name);

const USERS = "users";
const MEALS = "meals";
const EXERSIZES = "exersizes";
const DAYS = "days";
// make sure users exists in case we haven't setup it yet
createCollection(USERS);

const getUserPath = (user: User): string => USERS + "/" + user.uid;

export const createUser = async (user: User) => {
  await setDoc(doc(db, getUserPath(user)), JSON.parse(JSON.stringify(user)));
  await createCollection(getUserPath(user) + "/" + DAYS);
  await createCollection(getUserPath(user) + "/" + MEALS);
  await createCollection(getUserPath(user) + "/" + EXERSIZES);
  console.log("Finished setup!");
};

export const updateUser = async (user: User) =>
  await setDoc(doc(db, getUserPath(user)), JSON.parse(JSON.stringify(user)));

export const updateOrCreateDay = async (user: User, day: Day) =>
  setDoc(
    doc(db, getUserPath(user) + "/" + DAYS + "/" + day.uid),
    JSON.parse(JSON.stringify(day))
  );
export const updateOrCreateDayWithId = async (userUid: string, day: Day) => {
  const user: User = {
    uid: userUid,
    email: null
  };
  return updateOrCreateDay(user, day);
};

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const createDaysForSelectedMonth = (date: Date, days: Day[]) => {
  const numberOfDays = getDaysInMonth(date.getFullYear(), date.getMonth());
  let monthNum: string = (date.getMonth() + 1).toString();
  if (date.getMonth() < 9) {
    monthNum = "0" + monthNum;
  }
  for (let i = 0; i < numberOfDays; i++) {
    let uid = "";
    if (i < 9) {
      uid = `${date.getFullYear()}.${monthNum}.0${i + 1}`;
    } else {
      uid = `${date.getFullYear()}.${monthNum}.${i + 1}`;
    }
    days.push({
      month: `${date.getFullYear()}.${monthNum}`,
      uid: uid,
      calories: 0,
      protein: 0,
      carbs: 0,
      fats: 0,
      weight: null,
      waist: null,
      date: new Date(date.getFullYear(), date.getMonth(), i + 1),
      meals: [],
      exersizes: []
    });
  }
  // now save those to firebase...
};

export const getDay = async (userUid: string, dayId: string): Promise<Day> => {
  console.log(`Looking for: ${dayId}`);
  const user: User = {
    uid: userUid,
    email: null
  };
  const q = query(
    collection(db, getUserPath(user) + "/" + DAYS),
    where("uid", "==", `${dayId}`)
  );
  let days: Day[] = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(doc => days.push(doc.data() as Day));

  if (days.length < 1) {
    console.log("creating days");
    const month: Date = new Date(Date.parse(dayId.replace(".", "-")));
    createDaysForSelectedMonth(month, days);
    // now save all to firebase
    days.forEach(day => updateOrCreateDay(user, day));
    days.filter((day: Day) => day.uid === dayId);
  }

  return days[0];
};

export const getDaysForMonth = async (
  userUid: string,
  month: Date
): Promise<Day[]> => {
  const user: User = {
    uid: userUid,
    email: null
  };
  let days: Day[] = [];
  // first see if there are days
  let monthString = `${month.getFullYear()}.`;
  if (month.getMonth() + 1 < 10) {
    monthString += "0";
  }
  monthString += `${month.getMonth() + 1}`;
  console.log(`looking for: ${monthString}`);
  const q = query(
    collection(db, getUserPath(user) + "/" + DAYS),
    where("month", "==", `${monthString}`)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(doc => days.push(doc.data() as Day));

  if (days.length < 1) {
    // then we have to create them
    console.log("creating days");
    createDaysForSelectedMonth(month, days);
    // now save all to firebase
    days.forEach(day => updateOrCreateDay(user, day));
  }

  return days;
};
