import React, { useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Day from "../../data/Day";
import { RootState } from "../../Store";
import { daysActions, MealDayRequest } from "../../stores/days";

import cssClasses from "./Meal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { mealActions } from "../../stores/meals";
import Meal from "../../data/Meal";
import { FaEdit } from "react-icons/fa";
import NutritionChart from "../../components/NutritionChart/NutrtionChart";

function MealPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mealId } = useParams();
  const [amount, setAmount] = useState(1);
  // tell redux which day you are on and try to pull in the data required
  if (mealId === null || mealId === undefined) {
    return <Navigate to="/notfound/meal" />;
  }
  dispatch(mealActions.setSelectedMeal(mealId));

  const meal = useSelector<RootState, Meal | null>(state => {
    return state.meals.selectedMeal;
  });
  const selectedDayId = useSelector<RootState, string | null>(state => {
    if (state.days.selectedDay === null) {
      return "today";
    }
    return state.days.selectedDay.uid;
  });

  const addMeal = () => {
    const mealDayRequest: MealDayRequest = {
      day: null,
      meal: meal,
      amount: amount
    };
    if (amount !== 0) {
      dispatch(daysActions.addMealForDay(mealDayRequest));
    }

    navigate(`/days/${selectedDayId}`);
  };

  if (meal === null) {
    return <Navigate to="/notfound/meal" />;
  }

  return (
    <div className={cssClasses.outer}>
      <div className={cssClasses["info-panel"]}>
        <h1 className={cssClasses["meal-name"]}>{meal.name}</h1>
        <h4 className={cssClasses["meal-location"]}>{meal.route}</h4>
        <div>
          <input
            type="number"
            min="0"
            step="1"
            value={amount}
            onChange={e => setAmount(+e.target.value)}
          />
          <button className={cssClasses["add-meal"]} onClick={addMeal}>
            Add Meal for day
          </button>
        </div>
      </div>
      <div className={cssClasses["nutrition-breakdown"]}>
        <NutritionChart
          meal={meal}
          centerValues={true}
          showCalories={true}
          showMacros={true}
        />
      </div>
      <Link className="floating-action-button" to={"/meals/edit/" + meal.uid}>
        <FaEdit fontSize="23" />
      </Link>
    </div>
  );
}

export default MealPage;
