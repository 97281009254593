import React from "react";

import cssClasses from "./Home.module.css";

function Home() {
  return (
    <div className={cssClasses.outer}>
      <div className={cssClasses["info-panel"]}>
        <h1>Welcome to Drew's health tracking website!</h1>
        <br />
        This website will help you track both your nutrition info and your
        workouts
        <br />
        <br />
        <b>Note</b>this website is currently under development and I'm pretty
        sure its broken atm 😅
      </div>
    </div>
  );
}

export default Home;
export const HOME_ROUTE = "/home";
