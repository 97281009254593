import React from "react";

import cssClasses from "./AddWorkout.module.css";

function AddWorkout() {
    return <div className={cssClasses.outer}>
        add workout page here
    </div>;
}

export default AddWorkout;