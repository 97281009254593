import React from "react";

import { Link } from "react-router-dom";
import cssClasses from "./Meals.module.css";
import { FaSearch } from "react-icons/fa";
import Meal from "../../data/Meal";
import { RootState } from "../../Store";
import { useSelector, useDispatch } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import NutritionChart from "../../components/NutritionChart/NutrtionChart";

function MealsPage() {
  const foundMeals = useSelector<RootState, Meal[]>(
    state => state.meals.foundMeals
  );

  const foundMealComponents = foundMeals.map(meal => {
    const proteinId = meal.uid + "Protein";
    const fatsId = meal.uid + "Fats";
    const carbId = meal.uid + "Carbs";
    //https://www.canva.com/colors/color-wheel/ for color picking
    // https://www.npmjs.com/package/react-minimal-pie-chart
    // https://react-icons.github.io/react-icons/search
    return (
      <Link
        to={"/meals/" + meal.uid}
        className={cssClasses.mealContainer}
        key={meal.uid}
      >
        <div className={cssClasses.mealcard}>
          <div className={cssClasses["about-meal"]}>
            <h1>{meal.name}</h1>
            <p>{meal.route}</p>
          </div>
          <div className={cssClasses.nutritionbreakdown}>
            <NutritionChart meal={meal} showCalories={true} />
            <div
              className={cssClasses["nutritionbreakdown-numerals-container"]}
            >
              <div className={cssClasses["nutrition-numerals"]}>
                Protein: <span />
                {meal.protein}g
              </div>
              <div className={cssClasses["nutrition-numerals"]}>
                Carbs: <span />
                {meal.carbs}g
              </div>
              <div className={cssClasses["nutrition-numerals"]}>
                Fats: <span />
                {meal.fats}g
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  });
  const noMealsComponent =
    foundMeals.length < 1 ? (
      <div className={cssClasses.nomealsfound}>No Meals found!</div>
    ) : (
      <></>
    );
  return (
    <>
      <div className={cssClasses.outer}>
        <div className={cssClasses.searchrow}>
          <span />
          <div className={cssClasses.searchbar}>
            <input></input>
            {/* TODO make ^ search */}
            <span />
            <button type="submit">
              <FaSearch fontSize="23" />
            </button>
          </div>
          <span />
        </div>
        {noMealsComponent}
        <div className={cssClasses.mealContainer}>{foundMealComponents}</div>
      </div>
      {/* <div className="floating-navigate-to-top-button">^</div> */}
      <Link className="floating-action-button" to="/meals/newMeal">
        +
      </Link>
    </>
  );
}

export default MealsPage;
