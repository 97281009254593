import React from "react";

import cssClasses from "./EditWorkout.module.css";

function EditWorkout() {
    return <div className={cssClasses.outer}>
        edit workout page here
    </div>;
}

export default EditWorkout;