class User {
    uid: string;
    email: string | null;

    constructor(uid: string, email: string | null) {
        this.email = email;
        this.uid = uid;
    }
}

export default User;