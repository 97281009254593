import React from "react";
import ReactDom from 'react-dom';

import classes from './Background.module.css';

function Backdrop() {
    return <div className={classes.background}></div>;
}


function Background() {
    return <>
        {ReactDom.createPortal(<Backdrop />, document.getElementById('background')!)}
    </>;
}

export default Background;