import { useState } from "react";
import React from "react";

import cssClasses from "./Login.module.css";

import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword
} from "../../stores/auth";
import { RootState } from "../../Store";
import { daysActions } from "../../stores/days";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userId = useSelector<RootState, string | null>(state => state.auth.uid);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const alertFailure = () => {
    alert("Failed to Login!");
  };

  console.log("Rerender login page");
  console.log(userId);

  if (userId !== null) {
    dispatch(daysActions.setUserId(userId));
    console.log("logged in, rerouting");
    return <Navigate to="/home" />;
  }

  return (
    <div className={cssClasses.outer}>
      <div className={cssClasses.form}>
        <div className={cssClasses.row}>
          Email
          <span className={cssClasses.spacer} />
          <input
            value={email}
            placeholder="Email Address"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={cssClasses.row}>
          Password
          <span className={cssClasses.spacer} />
          <input
            value={password}
            placeholder="Password"
            type="password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <br />
        <div className={cssClasses.row}>
          <button
            onClick={async () => {
              const result = await logInWithEmailAndPassword(
                email,
                password,
                forceUpdate,
                alertFailure
              );
              if (result !== undefined) {
                dispatch(authActions.setUserId(result));
                navigate("/days");
              }
            }}
          >
            Login
          </button>
          <span className={cssClasses.spacer} />
          <button
            onClick={async () => {
              const result = await registerWithEmailAndPassword(
                email,
                password,
                forceUpdate,
                alertFailure
              );
              if (result !== undefined) {
                dispatch(authActions.setUserId(result));
                navigate("/days");
              }
            }}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
export const LOGIN_ROUTE = "/login";
