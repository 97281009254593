import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import AddWorkout from "./pages/AddWorkout/AddWorkout";
import EditWorkout from "./pages/EditWorkout/EditWorkout";
import Days from "./pages/Days/Days";
import DayPage from "./pages/Day/Day";

import { store } from "./Store";
import NotFound from "./pages/NotFound/NotFound";
import MealsPage from "./pages/Meals/Meals";
import MealPage from "./pages/Meal/Meal";
import AddMealPage from "./pages/AddMeal/AddMeal";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    {/* <FirebaseContext.Provider> */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="notfound" element={<NotFound />} />
          <Route path="notfound/:notFoundType" element={<NotFound />} />
          {/* TODO need to make it so not found can take in which type of not found it is */}
          <Route path="home" element={<Home />} />
          <Route path="" element={<Home />} />
          <Route path="days" element={<Days />} />
          <Route path="days/:dayId" element={<DayPage />} />
          <Route path="meals" element={<MealsPage />} />
          <Route path="meals/:mealId" element={<MealPage />} />
          <Route path="meals/edit/:mealId" element={<AddMealPage />} />
          <Route path="meals/newMeal" element={<AddMealPage />} />
          <Route path="/exersizes" element={<AddWorkout />} />
          <Route path="/exersizes/addWorkout" element={<AddWorkout />} />
          <Route path="/exersizes/:workoutId" element={<EditWorkout />} />
          <Route path="/exersizes/edit/:workoutId" element={<EditWorkout />} />
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
    {/* </FirebaseContext.Provider> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
