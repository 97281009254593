import { configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./stores/auth";
import { daysSlice } from "./stores/days";
import { mealSlice } from "./stores/meals";
// ...

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    days: daysSlice.reducer,
    meals: mealSlice.reducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
