import React from "react";
import { Outlet } from "react-router";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Background from "./components/Background/Background";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "./Store";
import {
  authActions,
  logInWithEmailAndPassword,
  signinDefault,
  signOutUser
} from "./stores/auth";
import { LOGIN_ROUTE } from "./pages/Login/Login";
import { HOME_ROUTE } from "./pages/Home/Home";

function App() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector<RootState, boolean>(state => {
    return state.auth.uid !== null;
  });
  const location = useLocation();
  if (
    !isLoggedIn &&
    location.pathname !== "/" &&
    location.pathname !== HOME_ROUTE &&
    location.pathname !== LOGIN_ROUTE
  ) {
    navigate(LOGIN_ROUTE, { replace: true });
  }

  function navigateIfNeedToLoginPage() {
    if (isLoggedIn) {
      signOutUser();
      dispatch(authActions.setUserId(null));
    }
    navigate(LOGIN_ROUTE, { replace: true });
  }
  function loginWithTest() {
    // login for us with default
  }

  const now = new Date();

  return (
    <>
      <Background />
      <header className="header">
        <Link to={HOME_ROUTE}>ReactHealth</Link>
        <nav>
          <ul>
            {!isLoggedIn ? (
              <></>
            ) : (
              <li>
                <Link to="/days">Days</Link>
              </li>
            )}
            {!isLoggedIn ? (
              <></>
            ) : (
              <li>
                {/* this might be better to make this a button and link over if its not today */}
                <Link
                  to={
                    "/days/" +
                    now.getFullYear().toString() +
                    "." +
                    (1 + now.getMonth()).toString() +
                    "." +
                    now.getDate().toString()
                  }
                >
                  Today
                </Link>
              </li>
            )}
            {!isLoggedIn ? (
              <></>
            ) : (
              <li>
                <Link to="/meals">Meals</Link>
              </li>
            )}
            <li>
              <button onClick={navigateIfNeedToLoginPage}>
                {isLoggedIn ? "Logout" : "Login"}
              </button>
            </li>
            <li>
              {!isLoggedIn && false ? (
                <button
                  onClick={async () => {
                    // TODO this isn't working
                    await logInWithEmailAndPassword(
                      "test@test.test",
                      "abc123123",
                      React.useReducer(() => ({}), {})[1] as () => void,
                      () => {}
                    );
                  }}
                >
                  Default Login
                </button>
              ) : (
                <></>
              )}
            </li>
          </ul>
        </nav>
        {/* TODO make it a drop down that lets you select either login things */}
      </header>
      <Outlet />
    </>
  );
}

export default App;
