import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
  Auth
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  setDoc,
  addDoc,
  doc
} from "firebase/firestore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { app, createUser, db } from "../firebase";
import User from "../data/User";

const auth = getAuth(app);

interface AuthState {
  uid: string | null;
}

const initialState: AuthState = {
  uid: null
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.uid = action.payload;
      return state;
    }
  }
});

export const authActions = authSlice.actions;

export const logInWithEmailAndPassword = async (
  email: string,
  password: string,
  onSuccessCallback: Function,
  onFailCallback: Function
) => {
  try {
    let userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    auth.updateCurrentUser(userCredential.user);
    onSuccessCallback();
  } catch (err) {
    console.error(err);
    onFailCallback();
  }
  if (auth.currentUser !== null) {
    return auth.currentUser.uid;
  }
};

export const registerWithEmailAndPassword = async (
  email: string,
  password: string,
  onSuccessCallback: Function,
  onFailCallback: Function
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user: User = new User(res.user.uid, res.user.email);
    await createUser(user);
    auth.updateCurrentUser(res.user);
    onSuccessCallback();
  } catch (err) {
    console.log(err);
    onFailCallback();
  }
};

export const signinDefault = (
  onSuccessCallback: Function,
  onFailCallback: Function
) => {
  logInWithEmailAndPassword(
    "test@test.test",
    "abc123123",
    onSuccessCallback,
    onFailCallback
  );
};

export const signOutUser = () => {
  authActions.setUserId(null);
  signOut(auth);
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
  }
};

onAuthStateChanged(auth, user => {
  if (user) {
    // currently logged in
    authActions.setUserId(user.uid);
  } else {
    // logged out
    authActions.setUserId(null);
  }
});
